import React from 'react';
import { func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockFooter.module.css';

const BlockFooter = props => {
  const { blockId, className, rootClassName, textClassName, text, options, index } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([text], options);

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const consentPreferencesLink = document.querySelector('.termly-display-preferences');
    if (consentPreferencesLink) {
      consentPreferencesLink.click();
    }
  };

  return (
    <BlockContainer id={blockId} className={classes}>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={text} options={options} />
          {!!index &&
            <a href="#" className={css.cookieLink} onClick={handleLinkClick}>
              Consent Preferences
            </a>
          }
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  text: null,
  options: null,
  index: null,
};

BlockFooter.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  textClassName: string,
  text: object,
  options: propTypeOption,
  index: number,
};

export default BlockFooter;
