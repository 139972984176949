import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';

const IMAGES_SHOULD_BE_RESIZED = [
  'Dr Shan Nanji, CEO',
  'Dr. Zoe Raw, Founder and Chief Scientific Officer',
  'Vitalii Gorobets',
  'Oliver Triggs, Chief Operating Officer',
  'Luke Taylor, Board Member',
  'Henry Chamberlain, Investor Director',
  'Simon Raw, Chief Technical Officer'
];

const FieldMedia = props => {
  const { className, media, sizes, options, linkTo } = props;

  const hasMediaField = hasDataInFields([media], options);
  const getReducedWidth = IMAGES_SHOULD_BE_RESIZED.includes(media?.alt)
    ? { width: '50%' }
    : null;

  const MediaContent = (
    <div style={getReducedWidth} className={classNames(className, css.media, { [css.clickable]: linkTo })}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  );

  return hasMediaField ? (
    linkTo ? (
      <Link to={linkTo}>{MediaContent}</Link>
    ) : (
      MediaContent
    )
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const hasMediaField = hasDataInFields([media], options);

 
  const extractLinkData = (textData) => {
    if (textData && textData.content) {
      const match = textData.content.match(/\[(.*?)\]\((.*?)\)/);
      return match ? { linkText: match[1], link: match[2], fullMatch: match[0] } : null;
    }
    return null;
  };

  const linkData = extractLinkData(text);

  const renderText = () => {
    if (!text || !text.content) return null;
    
    if (hasMediaField && linkData) {
      return <Field data={{ ...text, content: text.content.replace(linkData.fullMatch, '') }} options={options} />;
    } else if (!hasMediaField && linkData) {
      const textWithoutLink = text.content.replace(linkData.fullMatch, '');
      return (
        <>
          <Field data={{ ...text, content: textWithoutLink }} options={options} />
          <Link to={linkData.link} className={css.separateLink}>{linkData.linkText}</Link>
        </>
      );
    } else {
      return <Field data={text} options={options} />;
    }
  };

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
        linkTo={hasMediaField && linkData ? linkData.link : undefined}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          {renderText()}
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
