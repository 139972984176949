import React from 'react';

import { useHistory } from 'react-router-dom';
import { Field as FromField, Form as FinalForm } from 'react-final-form';

import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';
import { createResourceLocatorString } from '../../../../util/routes';
import { useConfiguration } from '../../../../context/configurationContext';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { Button, IconArrowHead } from '../../../../components';

import css from './SectionHero.module.css';

const SUGGESTIONS = [
  "Horse", "Andalusian Horse", "Jack Russell", "Dachshund", "Highland cattle"
];

const HeroSearchContainer = () => {
  const history = useHistory();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  const handleSearchSubmit = values => {
    if (!values?.search) {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}));
    }

    const search = values?.search && values?.search.toLowerCase();
    const originMaybe = isOriginInUse(config) ? { origin: search } : {};

    const searchParams = {
      ...originMaybe,
      keywords: search,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const handleSuggestionClick = (suggestion, form) => {
    form.change('search', suggestion);
    handleSearchSubmit(form.getState().values);
  };

  return (
    <FinalForm
      onSubmit={handleSearchSubmit}
      render={({ handleSubmit, form, values }) => (
        <>
          <form onSubmit={handleSubmit} className={css.searchForm}>
            <FromField
              className={css.searchInput}
              name="search"
              component="input"
              type="text"
              placeholder="Search"
            />
            <Button className={css.submitButton} type="submit">
              <div>Find your Breed</div>
              <IconArrowHead className={css.submitButtonArrow} direction="right" size="small" />
            </Button>
          </form>
          <div className={css.suggestionsBlock}>
            <div className={css.labelSuggestions}>People also search</div>
            <div className={css.suggestions}>
              {SUGGESTIONS.map(suggestion => (
                <button
                  key={suggestion}
                  onClick={() => handleSuggestionClick(suggestion, form)}
                  className={css.suggestionsButton}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    />
  );
};

export default HeroSearchContainer;
